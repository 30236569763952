<template>
  <div class="container">
    <!-- @tab-click="handleClick"
      :before-leave="beforeLeave" -->
    <el-tabs
      v-model="activeName"
      type="card"
    >
      <el-tab-pane
        v-if="!dealId"
        :label="labelTitle"
        name="first"
      >
        <div class="formBox">
          <div class="btns">
            <el-button
              type="warning"
              size="mini"
              @click="addQuestion"
            >保 存</el-button>
            <el-button
              size="mini"
              plain
              @click="resetForm('questionForm')"
            >重 置</el-button>
          </div>
          <div class="newForm">
            <el-form
              ref="questionForm"
              :model="questionForm"
              :rules="questionRules"
              label-width="120px"
            >
              <div class="queryItem1">
                <el-form-item
                  label="问题类型:"
                  prop="questionTypeTitle"
                >
                  <el-select
                    v-model="questionForm.questionTypeTitle"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in questionTypeArr"
                      :key="item.questionTypeId"
                      :label="item.title"
                      :value="item.title"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="来源单号:"
                  prop="oddNumber"
                >
                  <el-input
                    v-model="questionForm.oddNumber"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="会员号:"
                  prop="memberId"
                >
                  <el-select
                    v-model="questionForm.memberId"
                    placeholder="请选择"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in huiyuanList"
                      :key="index"
                      :label="item.nickname"
                      :value="item.memberId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="仓库:"
                  prop="storageId"
                >
                  <el-select
                    v-model="questionForm.storageId"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in cangkuArr"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item
                  label="问题描述:"
                  prop="describe"
                >
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="questionForm.describe"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="picBox">
            <div style="margin-bottom: 10px; color: #909090">上传图片：</div>
            <el-upload
              class="upload-demo"
              :action="activeUrl"
              list-type="picture-card"
              :limit="limitCount"
              :accept="'image/*'"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="uploadSectionFile"
              :file-list="fileList"
              :class="{ hide: hideUpload }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="问题处理"
        name="second"
        v-if="dealId"
      >
        <div class="formBox">
          <div class="btns">
            <el-button
              type="warning"
              size="mini"
              @click="dealSave"
            >保 存</el-button>
          </div>
          <el-form
            :model="dealQuestionForm"
            label-width="120px"
          >
            <div class="newForm">
              <div class="queryItem1">
                <el-form-item label="问题编号:">
                  <el-input
                    v-model="dealQuestionForm.questionNumber"
                    disabled
                  ></el-input>
                </el-form-item>
              </div>

              <div class="queryItem1">
                <el-form-item label="问题类型:">
                  <el-select
                    v-model="questionForm.questionTypeTitle"
                    placeholder="请选择"
                    disabled
                  >
                    <el-option
                      v-for="item in questionTypeArr"
                      :key="item.questionTypeId"
                      :label="item.title"
                      :value="item.title"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="来源单号:">
                  <el-input
                    v-model="questionForm.oddNumber"
                    placeholder="请输入"
                    disabled
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="反馈会员:">
                  <el-select
                    v-model="questionForm.memberId"
                    placeholder="请选择"
                    filterable
                    clearable
                    disabled
                  >
                    <el-option
                      v-for="(item, index) in huiyuanList"
                      :key="index"
                      :label="item.nickname"
                      :value="item.memberId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="仓库:">
                  <el-select
                    disabled
                    v-model="questionForm.storageId"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in cangkuArr"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="问题描述:">
                  <el-input
                    disabled
                    type="textarea"
                    :rows="2"
                    v-model="questionForm.describe"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="picBox">
                <div style="margin-bottom: 10px; color: #909090">问题图片：</div>
                <el-upload
                  disabled
                  class="upload-demo"
                  list-type="picture-card"
                  :accept="'image/*'"
                  :file-list="fileList"
                  :class="{ hide: hideUpload }"
                >
                </el-upload>
              </div>
              <!-- <div class="queryItem1">
                <el-form-item label="处理状态:">
                  <el-select
                    v-model="dealQuestionForm.handleStatus"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in dealStatus"
                      :key="item.statusVal"
                      :label="item.showName"
                      :value="item.statusVal"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div> -->
              <div class="queryItem1" style="margin-top:20px;">
                <el-form-item label="处理类型:">
                  <el-select
                    v-model="dealQuestionForm.handleTypeName"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in dealType"
                      :key="item.questionHandleTypeId"
                      :label="item.handleTypeName"
                      :value="item.questionHandleTypeId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="处理描述:">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="dealQuestionForm.handleComment"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <div
            class="picBox"
            style="margin-top: 60px"
          >
            <div style="margin-bottom: 10px; color: #909090">上传图片：</div>
            <el-upload
              class="upload-demo"
              :action="activeUrl"
              list-type="picture-card"
              :limit="limitCount"
              :accept="'image/*'"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="uploadSectionFile"
              :file-list="fileList_deal"
              :class="{ hide: hideUpload }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
      </el-tab-pane>
      <el-dialog :visible.sync="dialogVisible">
        <img
          width="100%"
          :src="dialogImageUrl"
          alt=""
        />
      </el-dialog>
    </el-tabs>
  </div>
</template>
<script>
import { apiList, Api } from '@/assets/js/api'
import tools from '@/assets/js/tool'

export default {
  data() {
    return {
      activeName: 'first',
      questionForm: {
        describe: '', //问题描述
        memberId: '', //会员号
        oddNumber: '', //单号
        pics: [], //图片数组
        questionTypeTitle: '', //问题类型标题
        storageId: '', //仓库id
      },
      dealQuestionForm: {},
      //     图片上传
      fileList: [],
      fileList_deal: [],
      huiyuanList: [], //会员列表
      activeUrl: '',
      limitCount: 10,
      dialogImageUrl: '',
      dialogVisible: false,
      hideUpload: false,
      expressNumEdit: undefined,
      cangkuArr: [], //  仓库下拉数组
      questionTypeArr: [], //  问题类型下拉数组
      questionRules: {
        courierNumber: [{ required: true, message: ' ', trigger: 'blur' }],
        exceptionTypeName: [
          { required: true, message: ' ', trigger: 'change' },
        ],
        storageId: [{ required: true, message: ' ', trigger: 'change' }],
      },
      questionId: undefined, //  异常包裹修改携带的id
      //     异常处理
      dealType: [],
      dealStatus: [],
      labelTitle: '新建问题',
      addid: '',
      dealId: '',
    }
  },
  created() {
    //  新建异常两种方式：页面输入快递单号拿包裹Id,外部传入快递单号
    this.activeUrl = this.common.uploadUrl
    // this.expressNumEdit = this.$route.query.num        //  获取外部传来的快递单号
    if (this.$route.query.dealId) {
      //  异常处理
      this.activeName = 'second'
      this.questionId = this.$route.query.dealId
      this.dealId = this.questionId
      this.getDealTypeList() //  获取问题处理类型
      this.getDealStatus() //  获取问题处理状态
      this.getDealDetail() //  获取问题处理详情
      this.addid = this.$route.query.id
      this.getQuestionDetail() //  获取问题详情
    } else {
      this.getCangkuArr() //  获取仓库下拉数组
      this.getQuestionType() //  获取问题类型
      if (this.$route.query.id) {
        //  问题修改
        this.labelTitle = '修改问题'
        this.questionId = this.$route.query.id //  获取异常包裹id（修改时用）
        this.addid = this.questionId
        this.getQuestionDetail() //  获取问题详情
      }
    }
  },
  mounted() {
    this.getMemberDownList()
  },
  methods: {
    //获取会员下拉列表
    getMemberDownList() {
      Api.getMemberDownList().then((res) => {
        //console.log(res.data.result.data)
        this.huiyuanList = res.data.result.data || []
      })
    },
    getCangkuArr() {
      //  获取仓库下拉
      Api.getStorageList().then((res) => {
        if (res.data.status === 'success') {
          this.cangkuArr = res.data.result
        }
      })
    },
    getQuestionType() {
      //  获取问题类型下拉
      Api.questionTypeList().then((res) => {
        if (res.data.status === 'success') {
          this.questionTypeArr = res.data.result
        }
      })
    },

    handleClick(val) {
      //  tab切换
      if (val.name === 'first') {
        this.resetForm('questionForm')
        this.getCangkuArr() //  获取仓库下拉数组
        this.getQuestionType() //  获取问题类型
      } else if (val.name === 'second') {
        if (this.questionId) {
        }
      }
    },
    beforeLeave(val) {
      //  阻止tab切换
      if (!this.questionId && val === 'second') {
        return false
      }
    },

    getQuestionDetail() {
      //  获取异常包裹详情
      Api.questionDetail({ questionId: this.addid }).then((res) => {
        if (res.data.status === 'success') {
          if (res.data.result.pics !== null) {
            res.data.result.pics.forEach((item) => {
              this.fileList.push({
                name: item.fileId,
                url: item.url,
              })
            })
          }

          this.questionForm = {
            describe: res.data.result.describe, //问题描述
            oddNumber: res.data.result.oddNumber, //单号
            pics: res.data.result.pics, //图片数组
            questionTypeTitle: res.data.result.questionTypeTitle, //问题类型标题
            storageId: res.data.result.storageId, //仓库id
            userId: res.data.result.userId, //用户id
            memberId: res.data.result.memberId,
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    //    ------------ 上传图片 ---------------
    handleRemove(file, fileList) {
      //  图片上传事件
      /* 移除图片 */
      if (this.activeName === 'first') {
        this.fileList = fileList
      } else {
        this.fileList_deal = fileList
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    uploadSectionFile(param) {
      // 上传图片
      const file = param.file
      const fileName = file.name
      const form = new FormData() //  根据后台需求数据格式
      form.append('file', file) //  文件对象
      form.append('fileName', fileName) //  本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      let signArr = [
        {
          key: 'fileName',
          val: fileName,
        },
      ]
      let sign = tools.getMD5Sign(signArr)
      form.append('sign', sign)
      Api.uploadFile(form).then((res) => {
        if (res.data.status === 'success') {
          if (this.activeName === 'first') {
            this.fileList.push({
              name: res.data.result.fileName,
              uid: res.data.result.redirectUrl,
              url: res.data.result.url,
            })
          } else {
            this.fileList_deal.push({
              name: res.data.result.fileName,
              uid: res.data.result.redirectUrl,
              url: res.data.result.url,
            })
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    resetForm(formName) {
      //  重置表单
      this.$refs[formName].resetFields()
      this.fileList = []
    },
    addQuestion() {
      //  新建问题
      this.$refs['questionForm'].validate((valid) => {
        if (valid) {
          this.questionForm.pics = []
          this.fileList.forEach((item) => {
            this.questionForm.pics.push(item.name)
          })

          if (!this.questionId) {
            let parm = {
              describe: this.questionForm.describe,
              memberId: this.questionForm.memberId,
              oddNumber: this.questionForm.oddNumber,
              pics: this.questionForm.pics,
              questionTypeTitle: this.questionForm.questionTypeTitle,
              storageId: this.questionForm.storageId,
            }
            let sign = tools.getSign(parm)
            parm.sign = sign
            Api.addQuestion(parm).then((res) => {
              if (res.data.status === 'success') {
                this.$message.success(res.data.message || '')
                setTimeout(() => {
                  tools.closePage()
                  this.reload()
                }, 500)
              }
            })
          } else {
            let parm = {
              describe: this.questionForm.describe,
              userId: this.questionForm.userId,
              oddNumber: this.questionForm.oddNumber,
              pics: this.questionForm.pics,
              questionTypeTitle: this.questionForm.questionTypeTitle,
              storageId: this.questionForm.storageId,
            }
            parm.questionId = this.questionId
            let sign = tools.getSign(parm)
            parm.sign = sign
            Api.updateQuestion(parm).then((res) => {
              if (res.data.status === 'success') {
                this.$message.success(res.data.message || '')
                setTimeout(() => {
                  this.$router.push('/exceptionhandling/complaint')
                }, 1000)
              }
            })
          }
        } else {
          this.$message.warning('请完整填写表单')
          return false
        }
      })
    },

    //    问题处理
    getDealDetail() {
      //  问题处理详情
      Api.handleQuestionDetail({ questionId: this.questionId }).then((res) => {
        if (res.data.status === 'success') {
          if (res.data.result.pics !== null) {
            res.data.result.pics.forEach((item) => {
              this.fileList_deal.push({
                name: item.fileId,
                url: item.url,
              })
            })
          }
          this.dealQuestionForm = res.data.result
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getDealTypeList() {
      //  问题处理类型
      Api.dealQuestionType().then((res) => {
        if (res.data.status === 'success') {
          this.dealType = res.data.result
        } else {
          console.log(`${res.data.message}--处理类型`)
        }
      })
    },
    getDealStatus() {
      //  获取问题处理状态
      Api.getStatusValList({
        tableAndFieldName: 'question_v1.handle_status',
      }).then((res) => {
        if (res.data.status === 'success') {
          this.dealStatus = res.data.result
        } else {
          console.log(`${res.data.message}--问题处理状态`)
        }
      })
    },
    dealSave() {
      //  问题处理按钮

      this.dealQuestionForm.pics = []
      this.fileList_deal.forEach((item) => {
        this.dealQuestionForm.pics.push(item.name)
      })
      let param = {
        handleComment: this.dealQuestionForm.handleComment || '',
        handleStatus: this.dealQuestionForm.handleStatus || '',
        handleTypeName: this.dealQuestionForm.handleTypeName || '',
        pics: this.dealQuestionForm.pics || [],
        questionId: this.dealQuestionForm.questionId || '',
      }
      let sign = tools.getSign(param)
      param.sign = sign
      Api.handleQuestion(param).then((res) => {
        if (res.data.status === 'success') {
          this.$message.success(res.data.message || '')
          setTimeout(() => {
            tools.closePage()
            this.reload()
          }, 500)
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.formBox {
  width: 100%;
  ._header {
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ .el-input__inner,
    .el-select {
      width: 300px;
      height: 30px;
    }
    margin-bottom: 10px;
  }
  .btns {
    margin: 20px;
  }
}

.newForm {
  .queryItem1 {
    width: 36%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.queryItem {
  width: 36%;
  margin: 0 auto;
  /deep/ .el-form-item {
    margin: 0;
  }
  /deep/ .el-input__inner {
    height: 30px;
  }
}

.picBox {
  width: 36%;
  margin: 0 auto;
}

.hide .el-upload--picture-card {
  display: none;
}
</style>
